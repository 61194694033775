.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  background-image: url("./assets/typing-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.typing-box-wrapper {
  background-color: #fff;
  max-width: 1150px;
  width: 1150px;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 50px;
}

.char,
.caret-char-left,
.correct-char,
.error-char,
.caret-char-right-correct,
.caret-char-right-error {
  color: #e0e0e0;
  font-size: 40px;
}

.correct-char,
.caret-char-right-correct {
  background: linear-gradient(286deg, #22bb9c 0%, #35debc 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error-char {
  color: #f75555;
}

.typebox-header {
  border-bottom: 0.6px solid #e0e0e0;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding: 25px 40px;
  margin-bottom: 45px;
}

.counter-time {
  color: #000;
  text-align: justify;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin: 0;
  width: 50px;
}

.counter {
  display: flex;
  width: 70%;
  align-items: center;
  column-gap: 20px;
}

.restart-wrapper {
  width: 25%;
  display: flex;
  justify-content: space-evenly;
  height: 30px;
}

.inactive-button {
  color: #bdbdbd;
  font-size: 16px;
}

.active-button {
  color: #ff805d;
  text-align: justify;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.2px;
  background-color: #ffeee9;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 15px;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: inherit !important;
}
.game-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background-color: transparent;
}

.canvas-blur {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.typing-statistics {
  background-color: #fff;
  padding: 16px;
  border-radius: 0px 0px 15px 15px;
  width: 460px;
  height: 425px;
  position: relative;
}

.typing-stat-bg {
  height: 100%;
  position: absolute;
  width: 430px;
}

.typing-stat-bg img {
  width: 100%;
  height: 490px;
  object-fit: cover;
  border-radius: 0px 0px 15px 15px;
}

.typing-statistics-header {
  position: absolute;
  z-index: 20;
  width: 600px;
  top: -90px;
}

.typing-statistics-header img:nth-of-type(1) {
  width: 240px;
  height: 106px;
  position: absolute;
  z-index: 21;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.typing-statistics-header img:nth-of-type(2) {
  width: 600px;
  height: 175px;
  position: absolute;
}

.typing-statistics__secondary {
  bottom: 40px;
  position: absolute;
  display: flex;
  column-gap: 50px;
}

.typing-statistics__stat-block {
  display: flex;
}
.typing-statistics__stat-block p {
  margin: 0;
}
.typing-statistics__stat-block__title,
.typing-statistics__stat-block__text {
  color: #fff;
  text-align: justify;
  font-size: 12px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.2px;
}
.typing-statistics__stat-block__text {
  font-size: 26px;
}

.typing-statistics__stat-block__img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.restart-block {
  position: absolute;
  z-index: 22;
  bottom: -23px;
}

.restart-btn {
  border: none;
  background-color: #fff;
  border-radius: 0px 0px 15px 15px;
}

.typing-statistics__student-xp {
  margin-top: 30px;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: 460px;
  max-width: 460px;
}

.typing-statistics__student-xp__text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.typing-statistics__student-xp__title {
  color: #000;
  margin: 0;
  text-align: center;
  font-size: 20px;
}

.total-xp {
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
}

.stat-wrapper {
  background-color: white;
  max-width: 1150px;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-stat-btn {
  border: none;
  background-color: transparent;
  color: #424242;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-left: 30px;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}

.full-stat-btn img {
  width: 28px;
  height: 28px;
  object-fit: contain;
}
